
import { UserLogin, CreateAccount, UserForgot, UserVerification, ResetPassword, OTPVerification, Welcome } from "../../pages";
import userRoutesMap from "../../routeControl/userRoutes";

export default function route() {
  return [
    {
      path: userRoutesMap.LOGIN.path,
      name: "Login",
      key: userRoutesMap.LOGIN.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <UserLogin />,
    },
    {
      path: userRoutesMap.SIGNUP.path,
      name: "SignUp",
      key: userRoutesMap.SIGNUP.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <CreateAccount />,
    },
    {
      path: userRoutesMap.FORGOT.path,
      name: "ForgotPassword",
      key: userRoutesMap.FORGOT.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <UserForgot />,
    },
    {
      path: userRoutesMap.OTP.path,
      name: "OTP Verification",
      key: userRoutesMap.OTP.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <UserVerification />,
    },
    {
      path: userRoutesMap.RESET.path,
      name: "Reset Password",
      key: userRoutesMap.RESET.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <ResetPassword />,
    },
    {
      path: userRoutesMap.CREATEOTP.path,
      name: "OTP Verification",
      key: userRoutesMap.CREATEOTP.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <UserVerification />,
    },
    // {
    //   path: userRoutesMap.WELCOME.path,
    //   name: "Welcome",
    //   key: userRoutesMap.WELCOME.path,
    //   private: false,
    //   common: false,
    //   belongsToHeader: false,
    //   belongsToFooter: false,
    //   element: <Welcome />,
    // },
  ];
}
