
import { LandingCMS, LandingHome, Landingfaq, Landingprivacy, Landingprivacyterms } from "../../pages";
// import userRoutesMap from "../../routeControl/userRoutes";
import userlandingRoutesMap from "../../routeControl/userlandingRoute";

export default function route() {
  return [
    {
      path: userlandingRoutesMap.LANDINGHOME.path,
      name: "Landing Home",
      key: userlandingRoutesMap.LANDINGHOME.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <LandingHome />,
    },
    {
      path: userlandingRoutesMap.LANDINGFAQ.path,
      name: "Landing Faq",
      key: userlandingRoutesMap.LANDINGFAQ.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <Landingfaq />,
    },
    {
      path: userlandingRoutesMap.LANDINGPRIVACY.path,
      name: "Landing Privacy",
      key: userlandingRoutesMap.LANDINGPRIVACY.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <LandingCMS />,
    },
    {
      path: userlandingRoutesMap.LANDINGTERMS.path,
      name: "Landing Terms & Conditions",
      key: userlandingRoutesMap.LANDINGTERMS.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <LandingCMS />,
    }
  ];
}
