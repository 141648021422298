import React, { useEffect, useState } from "react";
import { Container, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ImageElement } from "../UiElement";
import appNotificaton from "../../utils/notification";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/AuthSlice/index.slice";
import logger from "../../utils/logger";
import adminRoutesMap from "../../routeControl/adminRoutes";

function AdminHeader({ ToggleClass, isActive }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      let navbar = document.querySelector(".adminHeader").clientHeight;
      document.querySelector("body").style.paddingTop = `${navbar}px`;
    }, 300);
  });

  const accountLogout = async () => {
    try {
      appNotificaton({
        type: "success",
        message: "User Logged out",
      });
      dispatch(logout());
      navigate(adminRoutesMap.LOGIN.path);
      // setLoading(false);
    } catch (error) {
      logger(error);
    }
    // setLoading(false);
  };
  return (
    <>
      <header className="w-100 adminHeader">
        <Container fluid>
          <div className="w-100 d-flex align-items-center justify-content-between">
            <div to="/#" className="adminHeader_click" onClick={() => ToggleClass()}><em className={`${isActive ? 'tripon-close' : 'tripon-menu'}`} /></div>
            <ul className="list-unstyled mb-0 adminHeader_right">
              <li>
                <Link to="/admin/notification"><em /></Link>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle as="a">
                    <ImageElement source="default-img.jpg" alt="user-img" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu as="ul">
                    <Dropdown.Item as="li">
                      <Link to="/admin/my-profile">My Profile</Link>
                    </Dropdown.Item>
                    <Dropdown.Item as="li">
                      <Link to="/admin/login" onClick={(e) => { e.preventDefault(); accountLogout() }}>Logout</Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </Container>
      </header>
    </>
  );
}

export default AdminHeader;
