import React from "react";
import { Container } from "react-bootstrap";
import { ImageElement } from "../UiElement";
import { Link } from "react-router-dom";
import vendorlandingRoutesMap from "../../routeControl/vendorlandingRoute";

function UserLandingFooter() {
  return (
    <>
      <footer className="mainFooter bg-light">
        <Container>
          <div className="mainFooter_logo text-center">
            <ImageElement source="logo.svg" className="img-fluid" alt="logo" />
            <p>We provide the best tour facilities</p>
            <Link to={vendorlandingRoutesMap.LANDINGPRIVACY.path}>Privacy Policy |</Link>
            <Link to={vendorlandingRoutesMap.LANDINGTERMS.path}>Terms & Conditions |</Link>
            <Link to={vendorlandingRoutesMap.LANDINGFAQ.path}>Faq's </Link>
          </div>
          <div className="mainFooter_social">
            <Link to="/"><em className="tripon-facebook" /></Link>
            <Link to="/"><em className="tripon-instagram" /></Link>
            <Link to="/"><em className="tripon-linkedin" /></Link>
          </div>
          <div className="mainFooter_copy">©2024 Tournut All rights reserved.</div>
        </Container>
      </footer>
    </>
  );
}

export default UserLandingFooter;
