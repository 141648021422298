// import { baseRoutes } from "../../../helpers";

const accessRoute = {
  ERROR: {
    // path: `${baseRoutes.foundBaseRoutes}/404-page`,
    path: `*`,
  }
};

export default accessRoute;
