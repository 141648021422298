import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SuperAdminHeader, SuperAdminSidebar } from "../../components";
import AppLayout from "../app";
import { moduleRoutesList } from "../../routes";

function SuperAdminPrivateLayout() {
  let routeList = moduleRoutesList();
  const navigate = useNavigate();
  const [redirectpath, setRedirectPath] = useState("");
  const [isActive, setActive] = useState(false);
  const location = useLocation();

  const ToggleClass = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    if (redirectpath) {
      navigate(redirectpath);
    }
  }, [redirectpath]);

  useEffect(() => {
    if (isActive) {
      document.querySelector('body').classList.add('openSidebar');
    } else {
      document.querySelector('body').classList.remove('openSidebar');
    }
  }, [isActive]);

  useEffect(() => {
    if (window.innerWidth <= 1199) {
      document.querySelector('body').classList.remove('openSidebar');
      setActive(false)
    }
  }, [location?.pathname]);
  return (
    <>
      <AppLayout setRedirectPath={setRedirectPath}>
        <SuperAdminHeader ToggleClass={ToggleClass} isActive={isActive} />
        <SuperAdminSidebar setActive={setActive} routeList={routeList?.super} />
        <Outlet />
      </AppLayout>
    </>
  );
}

export default SuperAdminPrivateLayout;
