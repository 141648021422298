import { baseRoutes } from "../../../helpers";

const accessRoute = {
  SUPERSUPPORTLIST: {
    path: `${baseRoutes.superBaseRoutes}/support-list`,
  },
};

export default accessRoute;

  