import React from "react";

export const AdminLayout = React.lazy(() => import("./Admin/index.layout"))
export const SuperAdminLayout = React.lazy(() => import("./SuperAdmin/index.layout"))
export const UserLayout = React.lazy(() => import("./User/index.layout"))
export const FoundLayout = React.lazy(() => import("./Found/index.layout"))
export const UserlandingLayout = React.lazy(() => import("./Userlanding/index.layout"))
export const VendorlandingLayout = React.lazy(() => import("./Vendorlanding/index.layout"))

export const AppLayout = React.lazy(() => import("./app"));

export { default as AdminPublicLayout } from './Admin/public.layout';
export { default as AdminPrivateLayout } from './Admin/private.layout';

export { default as SuperAdminPublicLayout } from './SuperAdmin/public.layout';
export { default as SuperAdminPrivateLayout } from './SuperAdmin/private.layout';

export { default as UserPublicLayout } from './User/public.layout';
export { default as UserPrivateLayout } from './User/private.layout';

export { default as FoundPublicLayout } from './Found/public.layout';

export { default as UserlandingPublicLayout } from './Found/public.layout';
export { default as VendorlandingPublicLayout } from './Found/public.layout';
