import { baseRoutes } from "../../../helpers";

const accessRoute = {
  ADDTRIPLIST: {
    path: `${baseRoutes.adminBaseRoutes}/add-trip-list`,
  },
  ADDTRIP: {
    path: `${baseRoutes.adminBaseRoutes}/add-trip`,
  },
  EDITTRIP: {
    path: `${baseRoutes.adminBaseRoutes}/edit-trip`,
  },
  VIEWTRIP: {
    path: `${baseRoutes.adminBaseRoutes}/trip-details`,
  },
};

export default accessRoute;

  