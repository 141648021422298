import superauth from "./SuperAuth";
import superdashboard from "./SuperDashboard";
import userlist from "./UserList";
import vendorlist from "./VendorList";
import taxlist from "./TaxList";
import bookinglist from "./BookingList";
import superpayment from "./SuperPayment";
import superreportlist from "./SuperReportList";
import superreviewlist from "./SuperReviewList";
import supersupport from "./SuperSupport";
import supercms from "./SuperCMS";
import superprofile from "./SuperProfile";
import triplist from "./TripList";

const AccessControl = {
  ...superauth,
  ...superdashboard,
  ...userlist,
  ...vendorlist,
  ...taxlist,
  ...bookinglist,
  ...superpayment,
  ...superreportlist,
  ...superreviewlist,
  ...supersupport,
  ...supercms,
  ...superprofile,
  ...triplist,
};

export default AccessControl;
