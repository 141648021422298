
import { Support } from "../../../pages";
import adminRoutesMap from "../../../routeControl/adminRoutes";

export default function route() {
  return [
    {
      path: adminRoutesMap.SUPPORT.path,
      name: "Support",
      key: adminRoutesMap.SUPPORT.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-support",
      element: <Support />,
    }
  ];
}