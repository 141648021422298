

import { SuperSupport } from "../../../pages";
import superadminRoutesMap from "../../../routeControl/superadminRoutes";

export default function route() {
  return [
    {
      path: superadminRoutesMap.SUPERSUPPORTLIST.path,
      name: "Support",
      key: superadminRoutesMap.SUPERSUPPORTLIST.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-support",
      element: <SuperSupport />,
    }
  ];
}