import { lazy } from 'react';

export const HomePage = lazy(() => import("./Home/index.page"));
export const UserLogin = lazy(() => import("./UserLogin/index.page"));
export const CreateAccount = lazy(() => import("./CreateAccount/index.page"));
export const UserForgot = lazy(() => import("./UserForgot/index.page"));
export const UserVerification = lazy(() => import("./UserVerification/index.page"));
export const ResetPassword = lazy(() => import("./ResetPassword/index.page"));
export const OTPVerification = lazy(() => import("./OTPVerification/index.page"));
export const UpcomingTrip = lazy(() => import("./UpcomingTrip/index.page"));
export const Search = lazy(() => import("./Search/index.page"));
export const Filter = lazy(() => import("./Filter/index.page"));
export const TripDetails = lazy(() => import("./TripDetails/index.page"));
export const TourGuide = lazy(() => import("./TourGuide/index.page"));
export const Review = lazy(() => import("./Review/index.page"));
export const DaysPlan = lazy(() => import("./DaysPlan/index.page"));
export const Policies = lazy(() => import("./Policies/index.page"));
export const Terms = lazy(() => import("./Terms/index.page"));
export const Notification = lazy(() => import("./Notification/index.page"));
export const Checkout = lazy(() => import("./Checkout/index.page"));
export const UsersProfile = lazy(() => import("./UsersProfile/index.page"));
export const UsersProfileEdit = lazy(() => import("./UsersProfileEdit/index.page"));
export const ChangePassword = lazy(() => import("./ChangePassword/index.page"));
export const BookingHistory = lazy(() => import("./BookingHistory/index.page"));
export const BookingHistoryDetails = lazy(() => import("./BookingHistoryDetails/index.page"));
export const BookingPastDetails = lazy(() => import("./BookingPastDetails/index.page"));
export const AddReview = lazy(() => import("./AddReview/index.page"));
export const HelpSupport = lazy(() => import("./HelpSupport/index.page"));
export const UserFaq = lazy(() => import("./UserFaq/index.page"));
export const UserAbout = lazy(() => import("./UserAbout/index.page"));
export const Welcome = lazy(() => import("./Welcome/index.page"));
export const UserCMS = lazy(() => import("./UserCMS/index.page"));

// export { default as HomePage} from './Home/index.page';
// export { default as UserLogin} from './UserLogin/index.page';
// export { default as CreateAccount} from './CreateAccount/index.page';
// export { default as UserForgot} from './UserForgot/index.page';
// export { default as UserVerification} from './UserVerification/index.page';
// export { default as ResetPassword} from './ResetPassword/index.page';
// export { default as OTPVerification} from './OTPVerification/index.page';
// export { default as UpcomingTrip} from './UpcomingTrip/index.page';
// export { default as Search} from './Search/index.page';
// export { default as Filter} from './Filter/index.page';
// export { default as TripDetails} from './TripDetails/index.page';
// export { default as TourGuide} from './TourGuide/index.page';
// export { default as Review} from './Review/index.page';
// export { default as DaysPlan} from './DaysPlan/index.page';
// export { default as Policies} from './Policies/index.page';
// export { default as Terms} from './Terms/index.page';
// export { default as Notification} from './Notification/index.page';
// export { default as Checkout} from './Checkout/index.page';
// export { default as UsersProfile} from './UsersProfile/index.page';
// export { default as UsersProfileEdit} from './UsersProfileEdit/index.page';
// export { default as ChangePassword} from './ChangePassword/index.page';
// export { default as BookingHistory} from './BookingHistory/index.page';
// export { default as BookingHistoryDetails} from './BookingHistoryDetails/index.page';
// export { default as BookingPastDetails} from './BookingPastDetails/index.page';
// export { default as AddReview} from './AddReview/index.page';
// export { default as HelpSupport} from './HelpSupport/index.page';
// export { default as UserFaq} from './UserFaq/index.page';
// export { default as UserAbout} from './UserAbout/index.page';
// export { default as Welcome} from './Welcome/index.page';


