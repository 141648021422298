import { useRoutes } from "react-router-dom";
import { routes } from "./routes";
import { Suspense } from "react";
import './App.scss';
import ImageElement from "./components/UiElement/ImageElement";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



function App() {
  function RouteLayout({ path }) {
    const element = useRoutes(path);
    return element;
  }
  return (
    <>
      <ToastContainer />
      <Suspense fallback={<><div className="loaderBox"><ImageElement source="logo-icon.svg" alt="loader-icon" /></div></>}>
        <RouteLayout path={routes()} />
      </Suspense>
    </>
  );
}

export default App;
