import {lazy} from 'react';

export const SuperLogin = lazy(()=>import("./SuperLogin/index.page")); 
export const SuperDashboard = lazy(()=>import("./SuperDashboard/index.page")); 
export const SuperForgotpassword = lazy(()=>import("./SuperForgotpassword/index.page")); 
export const SuperForgototp = lazy(()=>import("./SuperForgototp/index.page")); 
export const SuperResetpassword = lazy(()=>import("./SuperResetpassword/index.page")); 
export const UserList = lazy(()=>import("./UserList/index.page")); 
export const UserDetails = lazy(()=>import("./UserDetails/index.page")); 
export const VendorList = lazy(()=>import("./VendorList/index.page")); 
export const TaxList = lazy(()=>import("./TaxList/index.page")); 
export const VendorDetails = lazy(()=>import("./VendorDetails/index.page")); 
export const BookingList = lazy(()=>import("./BookingList/index.page")); 
export const BookingDetails = lazy(()=>import("./BookingDetails/index.page")); 
export const SuperPaymentList = lazy(()=>import("./SuperPaymentList/index.page")); 
export const SuperReportList = lazy(()=>import("./SuperReportList/index.page")); 
export const SuperReviewList = lazy(()=>import("./SuperReviewList/index.page")); 
export const SuperSupport = lazy(()=>import("./SuperSupport/index.page")); 
export const SuperCMS = lazy(()=>import("./SuperCMS/index.page")); 
export const SuperProfile = lazy(()=>import("./SuperProfile/index.page")); 
export const TripList = lazy(()=>import("./TripList/index.page")); 
export const OfferBanner = lazy(()=>import("./OfferBanner/index.page")); 


// export {default as SuperLogin} from './SuperLogin/index.page';
// export {default as SuperDashboard} from './SuperDashboard/index.page';
// export {default as SuperForgotpassword} from './SuperForgotpassword/index.page';
// export {default as SuperForgototp} from './SuperForgototp/index.page';
// export {default as SuperResetpassword} from './SuperResetpassword/index.page';
// export {default as UserList} from './UserList/index.page';
// export {default as UserDetails} from './UserDetails/index.page';
// export {default as VendorList} from './VendorList/index.page';
// export {default as VendorDetails} from './VendorDetails/index.page';
// export {default as BookingList} from './BookingList/index.page';
// export {default as BookingDetails} from './BookingDetails/index.page';
// export {default as SuperPaymentList} from './SuperPaymentList/index.page';
// export {default as SuperReportList} from './SuperReportList/index.page';
// export {default as SuperReviewList} from './SuperReviewList/index.page';
// export {default as SuperSupport} from './SuperSupport/index.page';
// export {default as SuperCMS} from './SuperCMS/index.page';
// export {default as SuperProfile} from './SuperProfile/index.page';
// export {default as TripList} from './TripList/index.page';
// export {default as OfferBanner} from './OfferBanner/index.page';