import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AdminHeader, AdminSidebar } from "../../components";
import { AppLayout } from "..";
import { moduleRoutesList } from "../../routes";

function AdminPrivateLayout() {
  let routeList = moduleRoutesList();
  const navigate = useNavigate();
  const [redirectpath, setRedirectPath] = useState("");
  const [isActive, setActive] = useState(false);
  const location = useLocation()

  const ToggleClass = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    if (redirectpath) {
      navigate(redirectpath);
    }
  }, [redirectpath]);

  useEffect(() => {
    if (isActive) {
      document.querySelector('body').classList.add('openSidebar');
    } else {
      document.querySelector('body').classList.remove('openSidebar');
    }
  }, [isActive]);

  useEffect(() => {
    if (window.innerWidth <= 1199) {
      document.querySelector('body').classList.remove('openSidebar');
      setActive(false)
    }
  }, [location?.pathname]);

  return (
    <>
      <AppLayout setRedirectPath={setRedirectPath}>
        <AdminHeader ToggleClass={ToggleClass} isActive={isActive} />
        <AdminSidebar setActive={setActive} routeList={routeList?.admin} />
        <Outlet />
      </AppLayout>
    </>
  );
}

export default AdminPrivateLayout;
