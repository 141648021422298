import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AppLayout } from "..";

function AdminPublicLayout() {
  const navigate = useNavigate();
  const [redirectpath, setRedirectPath] = useState("");

  useEffect(() => {
    if (redirectpath) {
      navigate(redirectpath);
    }
  }, [redirectpath]);
  return (
    <AppLayout setRedirectPath={setRedirectPath}>
      <Outlet />
    </AppLayout>
  );
}

export default AdminPublicLayout;
