import { baseRoutes } from "../../../helpers";

const accessRoute = {
  REPORTLIST: {
    path: `${baseRoutes.adminBaseRoutes}/report-list`,
  }
};

export default accessRoute;

  