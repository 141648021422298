import { baseRoutes } from "../../../helpers";

const accessRoute = {
  PAYMENTLIST: {
    path: `${baseRoutes.adminBaseRoutes}/payment-list`,
  },
};

export default accessRoute;

  