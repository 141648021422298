import superadminRoutesMap from "../../routeControl/superadminRoutes";
import { SuperLogin, SuperForgotpassword, SuperResetpassword, SuperForgototp } from "../../pages";

export default function route() {
  return [
    {
      path: superadminRoutesMap.SUPERLOGIN.path,
      name: "Super Login",
      key: superadminRoutesMap.SUPERLOGIN.path,
      private: false,
      belongsToSidebar: false,
      element: <SuperLogin />,
    },
    {
      path: superadminRoutesMap.SUPERFORGOT.path,
      name: "Super Forgot Password",
      key: superadminRoutesMap.SUPERFORGOT.path,
      private: false,
      belongsToSidebar: false,
      element: <SuperForgotpassword />,
    },
    {
      path: superadminRoutesMap.SUPERFORGOTOTP.path,
      name: "Super Forgot OTP",
      key: superadminRoutesMap.SUPERFORGOTOTP.path,
      private: false,
      belongsToSidebar: false,
      element: <SuperForgototp />,
    },
    {
      path: superadminRoutesMap.SUPERRESTPWD.path,
      name: "Super Reset Password",
      key: superadminRoutesMap.SUPERRESTPWD.path,
      private: false,
      belongsToSidebar: false,
      element: <SuperResetpassword />,
    },
  ];
}
