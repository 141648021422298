import { baseRoutes } from "../../../helpers";

const accessRoute = {
  MYPROFILE: {
    path: `${baseRoutes.adminBaseRoutes}/my-profile`,
  }
};

export default accessRoute;

  