
import { VendorDetails, VendorList } from "../../../pages";
import superadminRoutesMap from "../../../routeControl/superadminRoutes";

export default function route() {
  return [
    {
      path: superadminRoutesMap.VENDORLIST.path,
      name: "Vendor List",
      key: superadminRoutesMap.VENDORLIST.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-vendor",
      element: <VendorList />,
    },
    {
      path: `${superadminRoutesMap.VENDORDETAILS.path}/:id`,
      name: "Vendor Details",
      key: `${superadminRoutesMap.VENDORDETAILS.path}/:id`,
      private: true,
      belongsToSidebar: false,
      element: <VendorDetails />,
    },
  ];
}
