import { baseRoutes } from "../../../helpers";

const accessRoute = {
  LOGIN: {
    path: `${baseRoutes.adminBaseRoutes}/login`,
  },
  SIGNUP: {
    path: `${baseRoutes.adminBaseRoutes}/signup`,
  },
  OTP: {
    path: `${baseRoutes.adminBaseRoutes}/verification-otp`,
  },
  VENDORPROFILE: {
    path: `${baseRoutes.adminBaseRoutes}/vendor-profile`,
  },
  FORGOT: {
    path: `${baseRoutes.adminBaseRoutes}/forgot-password`,
  },
  FORGOTOTP: {
    path: `${baseRoutes.adminBaseRoutes}/forgot-otp`,
  },
  RESTPWD: {
    path: `${baseRoutes.adminBaseRoutes}/reset-password`,
  },
};

export default accessRoute;
