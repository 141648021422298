
import { AddTripList, AddTrip, EditTrip, TripViewDetails } from "../../../pages";
import adminRoutesMap from "../../../routeControl/adminRoutes";

export default function route() {
  return [
    {
      path: adminRoutesMap.ADDTRIPLIST.path,
      name: "Add Trip Management",
      key: adminRoutesMap.ADDTRIPLIST.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-trips",
      element: <AddTripList />,
    },
    {
      path: adminRoutesMap.ADDTRIP.path,
      name: "Add Trip",
      key: adminRoutesMap.ADDTRIP.path,
      private: true,
      belongsToSidebar: false,
      element: <AddTrip />,
    },
    {
      path: `${adminRoutesMap.EDITTRIP.path}/:id`,
      name: "Edit Trip",
      key: `${adminRoutesMap.EDITTRIP.path}/:id`,
      private: true,
      belongsToSidebar: false,
      element: <AddTrip />,
    },
    {
      path: `${adminRoutesMap.VIEWTRIP.path}/:id`,
      name: "Trip View Details",
      key: `${adminRoutesMap.VIEWTRIP.path}/:id`,
      private: true,
      belongsToSidebar: false,
      element: <TripViewDetails />,
    },
  ];
}