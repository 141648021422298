import {lazy} from 'react';

export const AdminLogin = lazy(()=>import("./Login/index.page")); 
export const Dashboard = lazy(()=>import("./Dashboard/index.page")) ;
export const AdminSignup = lazy(()=>import("./Signup/index.page")) ;
export const AdminVerification = lazy(()=>import("./Verificationotp/index.page")) 
export const VendorProfile = lazy(()=>import("./Vendorprofile/index.page")) 
export const Forgotpassword = lazy(()=>import("./Forgotpassword/index.page")) 
export const Forgototp = lazy(()=>import("./Forgototp/index.page")) 
export const Resetpassword = lazy(()=>import("./Resetpassword/index.page")) 
export const AddTripList = lazy(()=>import("./AddTripList/index.page")) 
export const AddTrip = lazy(()=>import("./AddTrip/index.page")) 
export const EditTrip = lazy(()=>import("./EditTrip/index.page")) 
export const TripViewDetails = lazy(()=>import("./TripViewDetails/index.page")) 
export const BookingTripList = lazy(()=>import("./BookingTripList/index.page")) 
export const BookingViewDetails = lazy(()=>import("./BookingViewDetails/index.page")) 
export const PaymentList = lazy(()=>import("./PaymentList/index.page")) 
export const ReportList = lazy(()=>import("./ReportList/index.page")) 
export const Support = lazy(()=>import("./Support/index.page")) 
export const CMS = lazy(()=>import("./CMS/index.page")) 
export const MyProfile = lazy(()=>import("./MyProfile/index.page")) 
export const EditProfile = lazy(()=>import("./EditProfile/index.page")) 
export const ReviewList = lazy(()=>import("./ReviewList/index.page")) 


// export {default as AdminLogin} from './Login/index.page';
// export {default as Dashboard} from './Dashboard/index.page';
// export {default as AdminSignup} from './Signup/index.page';
// export {default as AdminVerification} from './Verificationotp/index.page';
// export {default as VendorProfile} from './Vendorprofile/index.page';
// export {default as Forgotpassword} from './Forgotpassword/index.page';
// export {default as Forgototp} from './Forgototp/index.page';
// export {default as Resetpassword} from './Resetpassword/index.page';
// export {default as AddTripList} from './AddTripList/index.page';
// export {default as AddTrip} from './AddTrip/index.page';
// export {default as EditTrip} from './EditTrip/index.page';
// export {default as TripViewDetails} from './TripViewDetails/index.page';
// export {default as BookingTripList} from './BookingTripList/index.page';
// export {default as BookingViewDetails} from './BookingViewDetails/index.page';
// export {default as PaymentList} from './PaymentList/index.page';
// export {default as ReportList} from './ReportList/index.page';
// export {default as Support} from './Support/index.page';
// export {default as CMS} from './CMS/index.page';
// export {default as MyProfile} from './MyProfile/index.page';
// export {default as EditProfile} from './EditProfile/index.page';
// export {default as ReviewList} from './ReviewList/index.page';