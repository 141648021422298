import { VendorlandingPublicLayout } from "../../layouts/index";
import publicRoutes from "./public.route";

export const vendorlandingRoutes = () => {
  return [
    {
      element: <VendorlandingPublicLayout />,
      children: [...publicRoutes()],
    }
  ];
};
