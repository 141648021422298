import { FoundPublicLayout } from "../../layouts/index";
import publicRoutes from "./public.route";

export const foundRoutes = () => {
  return [
    {
      element: <FoundPublicLayout />,
      children: [...publicRoutes()],
    }
  ];
};
