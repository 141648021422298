import { baseRoutes } from "../../../helpers";

const accessRoute = {
  BOOKLIST: {
    path: `${baseRoutes.superBaseRoutes}/booking-list`,
  },
  BOOKDETAILS: {
    path: `${baseRoutes.superBaseRoutes}/booking-details`,
  },
};

export default accessRoute;
  