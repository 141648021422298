import { baseRoutes } from "../../../helpers";

const accessRoute = {
  SUPERREVIEWLIST: {
    path: `${baseRoutes.superBaseRoutes}/review-list`,
  },
};

export default accessRoute;

  