import { baseRoutes } from "../../../helpers";

const accessRoute = {
  SUPERPROFILE: {
    path: `${baseRoutes.superBaseRoutes}/my-profile`,
  },
};

export default accessRoute;

  