import React from "react";
import { ImageElement } from "../../../components";
import { Link } from "react-router-dom";
import './error.page.scss';

function PageNotFound() {
  return (
    <>
      <div className="pageNot">
        <div>
          <ImageElement source="404-img.svg" alt="404-image" />
        </div>
        <Link to="/">Back to Home</Link>
      </div>
    </>
  );
}

export default PageNotFound;
