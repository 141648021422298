

import { SuperPaymentList } from "../../../pages";
import superadminRoutesMap from "../../../routeControl/superadminRoutes";

export default function route() {
  return [
    {
      path: superadminRoutesMap.SUPERPAYMENTLIST.path,
      name: "Payment",
      key: superadminRoutesMap.SUPERPAYMENTLIST.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-payment",
      element: <SuperPaymentList />,
    }
  ];
}