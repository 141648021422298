
import { ReportList } from "../../../pages";
import adminRoutesMap from "../../../routeControl/adminRoutes";

export default function route() {
  return [
    {
      path: adminRoutesMap.REPORTLIST.path,
      name: "Reports",
      key: adminRoutesMap.REPORTLIST.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-analytics",
      element: <ReportList />,
    }
  ];
}