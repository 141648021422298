
import { PaymentList } from "../../../pages";
import adminRoutesMap from "../../../routeControl/adminRoutes";

export default function route() {
  return [
    {
      path: adminRoutesMap.PAYMENTLIST.path,
      name: "Payment",
      key: adminRoutesMap.PAYMENTLIST.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-payment",
      element: <PaymentList />,
    }
  ];
}