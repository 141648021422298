

import { SuperProfile } from "../../../pages";
import superadminRoutesMap from "../../../routeControl/superadminRoutes";

export default function route() {
  return [
    {
      path: superadminRoutesMap.SUPERPROFILE.path,
      name: "My Profile",
      key: superadminRoutesMap.SUPERPROFILE.path,
      private: true,
      belongsToSidebar: false,
      element: <SuperProfile />,
    }
  ];
}