import { baseRoutes } from "../../../helpers";

const accessRoute = {
  BOOKINGTRIPLIST: {
    path: `${baseRoutes.adminBaseRoutes}/booking-trip-list`,
  },
  BOOKINGTRIPDETAILS: {
    path: `${baseRoutes.adminBaseRoutes}/booking-details`,
  },
};

export default accessRoute;
  