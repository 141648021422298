import { lazy } from 'react';

export const LandingHome = lazy(() => import("./LandingHome/index.page"));
export const Landingfaq = lazy(() => import("./Landingfaq/index.page"));
export const Landingprivacy = lazy(() => import("./Landingprivacy/index.page"));
export const Landingprivacyterms = lazy(() => import("./Landingprivacyterms/index.page"));
export const LandingCMS = lazy(() => import("./LandingCMS/index.page"));

// export { default as LandingHome } from './LandingHome/index.page';
// export { default as Landingfaq } from './Landingfaq/index.page';
// export { default as Landingprivacy } from './Landingprivacy/index.page';
// export { default as Landingprivacyterms } from './Landingprivacyterms/index.page';