import { baseRoutes } from "../../../helpers";

const accessRoute = {
  USERLIST: {
    path: `${baseRoutes.superBaseRoutes}/user-list`,
  },
  USERDETAILS: {
    path: `${baseRoutes.superBaseRoutes}/user-details`,
  },
};

export default accessRoute;
  