import { baseRoutes } from "../../../helpers";

const accessRoute = {
  LANDINGHOME: {
    path: `${baseRoutes.userlandingBaseRoutes}/user-landing`,
  },
  LANDINGFAQ: {
    path: `${baseRoutes.userlandingBaseRoutes}/user-landing/faq`,
  },
  LANDINGPRIVACY: {
    path: `${baseRoutes.userlandingBaseRoutes}/user-landing/privacy`,
  },
  LANDINGTERMS: {
    path: `${baseRoutes.userlandingBaseRoutes}/user-landing/terms`,
  }
};

export default accessRoute;
