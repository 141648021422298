import adminRoutesMap from "../../routeControl/adminRoutes";
import { AdminLogin, AdminSignup, AdminVerification, VendorProfile, Forgotpassword, Forgototp, Resetpassword } from "../../pages";

export default function route() {
  return [
    {
      path: adminRoutesMap.LOGIN.path,
      name: "Admin Login",
      key: adminRoutesMap.LOGIN.path,
      private: false,
      belongsToSidebar: false,
      element: <AdminLogin />,
    },
    {
      path: adminRoutesMap.SIGNUP.path,
      name: "Admin Signup",
      key: adminRoutesMap.SIGNUP.path,
      private: false,
      belongsToSidebar: false,
      element: <AdminSignup />,
    },
    {
      path: adminRoutesMap.OTP.path,
      name: "Admin Verification",
      key: adminRoutesMap.OTP.path,
      private: false,
      belongsToSidebar: false,
      element: <AdminVerification />,
    },
    {
      path: adminRoutesMap.VENDORPROFILE.path,
      name: "Vendor Profile",
      key: adminRoutesMap.VENDORPROFILE.path,
      private: false,
      belongsToSidebar: false,
      element: <VendorProfile />,
    },
    {
      path: adminRoutesMap.FORGOT.path,
      name: "Forgot Password",
      key: adminRoutesMap.FORGOT.path,
      private: false,
      belongsToSidebar: false,
      element: <Forgotpassword />,
    },
    {
      path: adminRoutesMap.FORGOTOTP.path,
      name: "Forgot Otp",
      key: adminRoutesMap.FORGOTOTP.path,
      private: false,
      belongsToSidebar: false,
      element: <AdminVerification />,
    },
    {
      path: adminRoutesMap.RESTPWD.path,
      name: "Reset Password",
      key: adminRoutesMap.RESTPWD.path,
      private: false,
      belongsToSidebar: false,
      element: <Resetpassword />,
    },
  ];
}
