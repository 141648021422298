import { baseRoutes } from "../../../helpers";

const accessRoute = {
  LOGIN: {
    path: `${baseRoutes.userBaseRoutes}login`,
  },
  SIGNUP: {
    path: `${baseRoutes.userBaseRoutes}create-account`,
  },
  FORGOT: {
    path: `${baseRoutes.userBaseRoutes}forgot-password`,
  },
  OTP: {
    path: `${baseRoutes.userBaseRoutes}verification`,
  },
  RESET: {
    path: `${baseRoutes.userBaseRoutes}reset-password`,
  },
  CREATEOTP: {
    path: `${baseRoutes.userBaseRoutes}otp-verification`,
  },
  WELCOME: {
    path: `${baseRoutes.userBaseRoutes}`,
  }
};

export default accessRoute;
