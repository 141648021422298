import { UserlandingPublicLayout } from "../../layouts/index";
import publicRoutes from "./public.route";

export const userlandingRoutes = () => {
  return [
    {
      element: <UserlandingPublicLayout />,
      children: [...publicRoutes()],
    }
  ];
};
