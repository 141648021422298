import { SuperAdminPublicLayout, SuperAdminPrivateLayout } from "../../layouts/index";
import publicRoutes from "./public.route";
import privateRoutes from "./private.route";

export const superadminRoutes = () => {
  return [
    {
      element: <SuperAdminPublicLayout />,
      children: [...publicRoutes()],
    },
    {
      element: <SuperAdminPrivateLayout />,
      children: [...privateRoutes()],
    },
  ];
};
