import React from "react";
import config from "../../../config/index.js";

function ImageElement({ previewSource = "", source,userType="common", alt ="image", ...rest }) {
  const imageUrl = {
    admin: config.ADMIN_IMAGE_URL,
    user: config.IMAGE_URL,
    super: config.SUPER_IMAGE_URL,
    common: config.COMMON_IMAGE_URL,
    userLanding: config.LANDING_IMAGE_URL,
  }

  return (
    <>
      {previewSource ? (
        <img src={previewSource} alt={alt} {...rest} />
      ) : (
        <img src={`${imageUrl[userType]}/${source}`} alt={alt} {...rest} />
      )}
    </>
  );
}

export default ImageElement;
