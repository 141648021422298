import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/AuthSlice/index.slice";
import { generatePath, useLocation, useParams } from "react-router-dom";
import { getLocalStorageToken } from "../../utils/common.util";
import { getCompletePathList } from "../../routes";
// import appNotificaton from "../../utils/notification";

function AppLayout({ children, setRedirectPath }) {
  const routes = getCompletePathList();
  const location = useLocation();
  const params = useParams();
  const userData = useSelector(selectUserData);
  const userToken = getLocalStorageToken();

  const getGeneratedPath = (data) => {
    try {
      return generatePath(data, params);
    } catch (error) {
      return data;
    }
  };

  const activeRoute = useMemo(() => {
    if (routes?.length > 0) {
      return routes?.find((e) => {
        let route = getGeneratedPath(e.path);
        const browserPath = location.pathname;

        return route === browserPath;
      });
    }
  }, [location, routes]);

  const isPrivate = useMemo(() => {
    return activeRoute?.private;
  }, [activeRoute]);

  const userType = useMemo(() => {
    let usertype;
    if (location.pathname.includes("super-admin")) {
      usertype = "super-admin"
    } else if (location.pathname.includes("admin")) {
      usertype = "vendor"
    } else {
      usertype = "user"
    }

    return usertype
  }, [location])

  const isValid = useMemo(() => {
    let checkUserType = userData?.type ?? userType
    if (activeRoute?.name) {
      if (checkUserType === "user" && activeRoute?.common === true) {
        return true;
      } else if (
        activeRoute.private &&
        userData?.type === userType
        // activeRoute.routeAccess.includes(userData?.type)
      ) {
        if (userToken) {
          return true;
        } else {
          return false;
        }
      } else {
        if (userToken) {
          return false;
        }

        if (activeRoute.private === false) {
          return true;
        } else {
          return false;
        }
      }
    }
  }, [activeRoute, userData?.type, userToken, userType]);

  const checkValid = () => {
    if (!isValid) {
      let privatePath = "/dashboard",
        publicPath = "/";

      let checkUserType = userData?.type ?? userType
      if (checkUserType === "super-admin") {
        privatePath = "/super-admin/dashboard";
        publicPath = "/super-admin/login";
      } else if (checkUserType === "vendor") {
        privatePath = "/admin/dashboard";
        publicPath = "/admin/login";
      } else if (checkUserType === "user") {
        privatePath = "/";
        publicPath = "/";
      }
      if (isPrivate) {
        setRedirectPath(publicPath);
      } else if (isPrivate === false) {
        setRedirectPath(privatePath);
      } else {
        // setRedirectPath(location.pathname);
      }
      // appNotificaton({
      //   type: "warning",
      //   message: "Unauthorised Access",
      // });
    }
  };

  useEffect(() => {
    checkValid();
  }, [location.pathname]);

  return <>{isValid && children}</>;
}

export default AppLayout;
