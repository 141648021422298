
import { PageNotFound } from "../../pages";
import foundRoutesMap from "../../routeControl/foundRoutes";

export default function route() {
  return [
    {
      path: foundRoutesMap.ERROR.path,
      name: "Error",
      key: foundRoutesMap.ERROR.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <PageNotFound />,
    }
  ];
}
