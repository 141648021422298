import { baseRoutes } from "../../../helpers";

const accessRoute = {
  VENDORLIST: {
    path: `${baseRoutes.superBaseRoutes}/vendor-list`,
  },
  VENDORDETAILS: {
    path: `${baseRoutes.superBaseRoutes}/vendor-details`,
  },
};

export default accessRoute;
  