import { baseRoutes } from "../../../helpers";

const accessRoute = {
  SUPERPAYMENTLIST: {
    path: `${baseRoutes.superBaseRoutes}/payment-list`,
  },
};

export default accessRoute;

  