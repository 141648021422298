import { createSlice } from "@reduxjs/toolkit";
import { removeLocalStorageToken } from "../../utils/common.util";
import logger from "../../utils/logger";

const initialState = {
  userData: {},
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginAction: (state, action) => {
      return (state = {
        ...state,
        userData: { ...action.payload },
      });
    },
    logoutAction: (state) => {
      return (state = {
        ...state,
        userData: {},
      });
    },
  },
});
export const { logoutAction, loginAction } = authSlice.actions;


export const loginData = (data) => async (dispatch) => {
  try {
    dispatch(loginAction(data));
  } catch (error) {
    logger(error);
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch(logoutAction());
    removeLocalStorageToken();
  } catch (error) {
    logger(error);
  }
};

export const selectUserData = (state) => state.auth.userData;
export const selectUsertype = (state) => state.auth.type;

export default authSlice.reducer;
