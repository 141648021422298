
import { MyProfile } from "../../../pages";
import adminRoutesMap from "../../../routeControl/adminRoutes";

export default function route() {
  return [
    {
      path: adminRoutesMap.MYPROFILE.path,
      name: "Report List",
      key: adminRoutesMap.MYPROFILE.path,
      private: true,
      belongsToSidebar: false,
      element: <MyProfile />,
    }
  ];
}