
import { TaxList } from "../../../pages";
import superadminRoutesMap from "../../../routeControl/superadminRoutes";

export default function route() {
  return [
    {
      path: superadminRoutesMap.TAXLIST.path,
      name: "Tax List",
      key: superadminRoutesMap.TAXLIST.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-rupees",
      element: <TaxList />,
    }
  ];
}
