
import { CMS } from "../../../pages";
import adminRoutesMap from "../../../routeControl/adminRoutes";

export default function route() {
  return [
    {
      path: adminRoutesMap.CMS.path,
      name: "CMS",
      key: adminRoutesMap.CMS.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-file",
      element: <CMS />,
    }
  ];
}