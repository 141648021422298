
import { UserDetails, UserList } from "../../../pages";
import superadminRoutesMap from "../../../routeControl/superadminRoutes";

export default function route() {
  return [
    {
      path: superadminRoutesMap.USERLIST.path,
      name: "User List",
      key: superadminRoutesMap.USERLIST.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-user",
      element: <UserList />,
    },
    {
      path: `${superadminRoutesMap.USERDETAILS.path}/:id`,
      name: "User Details",
      key: `${superadminRoutesMap.USERDETAILS.path}/:id`,
      private: true,
      belongsToSidebar: false,
      element: <UserDetails />,
    },
  ];
}
