

import { SuperReviewList } from "../../../pages";
import superadminRoutesMap from "../../../routeControl/superadminRoutes";

export default function route() {
  return [
    {
      path: superadminRoutesMap.SUPERREVIEWLIST.path,
      name: "Review List",
      key: superadminRoutesMap.SUPERREVIEWLIST.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-star",
      element: <SuperReviewList />,
    }
  ];
}