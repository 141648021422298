const config = {
    IMAGE_URL: process.env.REACT_APP_IMAGE_PATH,
    NAME_KEY: process.env.REACT_APP_NAME_KEY,
    ADMIN_IMAGE_URL: process.env.REACT_APP_ADMIN_IMAGE_PATH,
    SUPER_IMAGE_URL: process.env.REACT_APP_SUPER_IMAGE_PATH,
    COMMON_IMAGE_URL: process.env.REACT_APP_COMMON_IMAGE_PATH,
    LANDING_IMAGE_URL: process.env.REACT_APP_LANDING_IMAGE_PATH,
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/",
    RAZORPAY_KEY: process.env.REACT_APP_RAZORPAY_KEY
};

export default config;
