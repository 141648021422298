import React, { useEffect, useState } from "react";
import { Container, Navbar } from "react-bootstrap";
import { ImageElement } from "../UiElement";
import { Link } from "react-router-dom";
import adminRoutesMap from "../../routeControl/adminRoutes";
import vendorlandingRoutesMap from "../../routeControl/vendorlandingRoute";

function UserLandingHeader() {
  useEffect(() => {
    setTimeout(() => {
      let navbar = document.querySelector(".mainHeader").clientHeight;
      document.querySelector("body").style.paddingTop = `${navbar}px`;
    }, 300);
  });
  const [scroll, setScroll] = useState(0);
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 50
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
      }
    })
  });
  return (
    <>
      <header className={`mainHeader ${scroll ? 'scroll' : ''}`}>
        <Container>
          <Navbar className="p-0">
            <Navbar.Brand href={vendorlandingRoutesMap.LANDINGHOME.path} className="m-0 p-0"><ImageElement source="logo-sidebar.svg" alt="logo" /></Navbar.Brand>
            <div className="ms-auto">
              <Link to={adminRoutesMap.LOGIN.path} target="_blank" className="btn btn-primary" >Login</Link>
            </div>
          </Navbar>
        </Container>
      </header>
    </>
  );
}

export default UserLandingHeader;
