import { baseRoutes } from "../../../helpers";

const accessRoute = {
  TRIPSLIST: {
    path: `${baseRoutes.superBaseRoutes}/trip-list`,
  },
  VIEWTRIP: {
    path: `${baseRoutes.superBaseRoutes}/trip-details`,
  },
};

export default accessRoute;
