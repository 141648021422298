import React from "react";
import { Outlet } from "react-router-dom";

function FoundPublicLayout() {
  return (
    <>
    <Outlet />
    </>
  );
}

export default FoundPublicLayout;
