import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { ImageElement } from "../UiElement";
import { Link } from "react-router-dom";

function UserHeader() {
  return (
    <>
      <div className="mainHeader">
        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand href="/" className="p-0 me-0">
              <ImageElement source="logo.png" alt="logo-img"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav as="ul" className="ms-auto align-items-center">
                <Nav.Item>
                  <Link to="/" className="nav-link">Home</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/login" className="btn btn-primary btn-sm">Login</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/admin/login" className="btn btn-danger btn-sm">Vendor</Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default UserHeader;
