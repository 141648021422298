import { baseRoutes } from "../../../helpers";

const accessRoute = {
  CMSLIST: {
    path: `${baseRoutes.superBaseRoutes}/cms-list`,
  },
  BANNER: {
    path: `${baseRoutes.superBaseRoutes}/offer-banner`,
  }
};

export default accessRoute;

  