import { baseRoutes } from "../../../helpers";

const accessRoute = {
  CMS: {
    path: `${baseRoutes.adminBaseRoutes}/cms`,
  }
};

export default accessRoute;

  