
import { EditProfile } from "../../../pages";
import adminRoutesMap from "../../../routeControl/adminRoutes";

export default function route() {
  return [
    {
      path: adminRoutesMap.EDITPROFILE.path,
      name: "Report List",
      key: adminRoutesMap.EDITPROFILE.path,
      private: true,
      belongsToSidebar: false,
      element: <EditProfile />,
    }
  ];
}