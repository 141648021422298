
import { OfferBanner, SuperDashboard } from "../../../pages";
import superadminRoutesMap from "../../../routeControl/superadminRoutes";

export default function route() {
  return [
    {
      path: superadminRoutesMap.SUPERDASHBOARD.path,
      name: "Dashboard",
      key: superadminRoutesMap.SUPERDASHBOARD.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-dashboard",
      element: <SuperDashboard />,
    },
    {
      path: superadminRoutesMap.BANNER.path,
      name: "Banner",
      key: superadminRoutesMap.BANNER.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-percent",
      element: <OfferBanner />,
    }
  ];
}
