import { baseRoutes } from "../../../helpers";

const accessRoute = {
  LANDINGHOME: {
    path: `${baseRoutes.adminBaseRoutes}`,
  },
  LANDINGFAQ: {
    path: `${baseRoutes.adminBaseRoutes}/faq`,
  },
  LANDINGPRIVACY: {
    path: `${baseRoutes.adminBaseRoutes}/privacy`,
  },
  LANDINGTERMS: {
    path: `${baseRoutes.adminBaseRoutes}/terms`,
  }
};

export default accessRoute;
