

import { SuperCMS } from "../../../pages";
import superadminRoutesMap from "../../../routeControl/superadminRoutes";

export default function route() {
  return [
    {
      path: superadminRoutesMap.CMSLIST.path,
      name: "CMS",
      key: superadminRoutesMap.CMSLIST.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-file",
      element: <SuperCMS />,
    },

  ];
}