import { baseRoutes } from "../../../helpers";

const accessRoute = {
  EDITPROFILE: {
    path: `${baseRoutes.adminBaseRoutes}/edit-profile`,
  }
};

export default accessRoute;

  