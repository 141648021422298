
import { Dashboard } from "../../../pages";
import adminRoutesMap from "../../../routeControl/adminRoutes";

export default function route() {
  return [
    {
      path: adminRoutesMap.DASHBOARD.path,
      name: "Dashboard",
      key: adminRoutesMap.DASHBOARD.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-dashboard",
      element: <Dashboard />,
    }
  ];
}
