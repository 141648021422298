import DashboardRoutes from "./SuperDashboard/index.route";
import UserListRoutes from "./UserList/index.route";
import VendorListRoutes from "./VendorList/index.route";
import TaxListRoutes from "./TaxList/index.route";
import BookingListRoutes from "./BookingList/index.route";
import SuperPaymentRoutes from "./SuperPayment/index.route";
import SuperReportListRoutes from "./SuperReportList/index.route";
import SuperReviewListRoutes from "./SuperReviewList/index.route";
import SuperSupportRoutes from "./SuperSupport/index.route";
import SuperCMSRoutes from "./SuperCMS/index.route";
import SuperProfileRoutes from "./SuperProfile/index.route";
import TripListRoutes from "./TripList/index.route";

export default function route() {
  return [
    ...DashboardRoutes(),
    ...UserListRoutes(),
    ...VendorListRoutes(),
    ...TaxListRoutes(),
    ...TripListRoutes(),
    ...BookingListRoutes(),
    ...SuperPaymentRoutes(),
    ...SuperReportListRoutes(),
    ...SuperReviewListRoutes(),
    ...SuperSupportRoutes(),
    ...SuperCMSRoutes(),
    ...SuperProfileRoutes(),
  ];
}
