import DashboardRoutes from "./Dashboard/index.route";
import TripsRoutes from "./Trips/index.route";
import BookingRoutes from "./Booking/index.route";
import PaymentRoutes from "./Payment/index.route";
import ReportRoutes from "./Report/index.route";
import SupportRoutes from "./Support/index.route";
import CMSRoutes from "./CMS/index.route";
import MyProfileRoutes from "./MyProfile/index.route";
import EditProfileRoutes from "./EditProfile/index.route";
import ReviewListRoutes from "./ReviewList/index.route";

export default function route() {
  return [
    ...DashboardRoutes(),
    ...TripsRoutes(),
    ...BookingRoutes(),
    ...PaymentRoutes(),
    ...ReportRoutes(),
    ...ReviewListRoutes(),
    ...SupportRoutes(),
    ...CMSRoutes(),
    ...MyProfileRoutes(),
    ...EditProfileRoutes(),
  ];
}
