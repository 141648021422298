
import { VendorLandingCMS, VendorLandingHome, VendorLandingfaq } from "../../pages";
import vendorlandingRoutesMap from "../../routeControl/vendorlandingRoute";

export default function route() {
  return [
    {
      path: vendorlandingRoutesMap.LANDINGHOME.path,
      name: "Landing Home",
      key: vendorlandingRoutesMap.LANDINGHOME.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <VendorLandingHome />,
    },
    {
      path: vendorlandingRoutesMap.LANDINGFAQ.path,
      name: "Landing Faq",
      key: vendorlandingRoutesMap.LANDINGFAQ.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <VendorLandingfaq />,
    },
    {
      path: vendorlandingRoutesMap.LANDINGPRIVACY.path,
      name: "Landing Privacy",
      key: vendorlandingRoutesMap.LANDINGPRIVACY.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <VendorLandingCMS />,
    },
    {
      path: vendorlandingRoutesMap.LANDINGTERMS.path,
      name: "Landing Terms & Conditions",
      key: vendorlandingRoutesMap.LANDINGTERMS.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <VendorLandingCMS />,
    }
  ];
}
