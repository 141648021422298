import { baseRoutes } from "../../../helpers";

const accessRoute = {
  SUPERLOGIN: {
    path: `${baseRoutes.superBaseRoutes}/login`,
  },
  SUPERFORGOT: {
    path: `${baseRoutes.superBaseRoutes}/forgot-password`,
  },
  SUPERFORGOTOTP: {
    path: `${baseRoutes.superBaseRoutes}/forgot-otp`,
  },
  SUPERRESTPWD: {
    path: `${baseRoutes.superBaseRoutes}/reset-password`,
  },
};

export default accessRoute;
