import React from "react";
import { Container  } from "react-bootstrap";
import { ImageElement } from "../UiElement";
import { Link } from "react-router-dom";

function UserLandingFooter() {
  return (
    <>
      <footer className="mainFooter bg-light">
        <Container>
          <div className="mainFooter_logo text-center">
            <ImageElement source="logo.svg" className="img-fluid" alt="logo" />
            <p>We provide the best tour facilities</p>
            <Link to="/user-landing/privacy">Privacy Policy |</Link>
            <Link to="/user-landing/terms">Terms & Conditions |</Link>
            <Link to="/user-landing/faq">Faq's </Link>
          </div> 
          <div className="mainFooter_social">
            <Link to="/"><em className="tripon-facebook"/></Link>
            <Link to="/"><em className="tripon-instagram"/></Link>
            <Link to="/"><em className="tripon-linkedin"/></Link>
          </div>
          <div className="mainFooter_copy">©2024 Tournut All rights reserved.</div>
        </Container>
      </footer>
    </>
  );
}

export default UserLandingFooter;
