
import { ReviewList } from "../../../pages";
import adminRoutesMap from "../../../routeControl/adminRoutes";

export default function route() {
  return [
    {
      path: adminRoutesMap.REVIEW.path,
      name: "Review List",
      key: adminRoutesMap.REVIEW.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-star",
      element: <ReviewList />,
    }
  ];
}