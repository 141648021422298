import auth from "./Auth";
import dashboard from "./Dashboard";
import trips from "./Trips";
import booking from "./Booking";
import payment from "./Payment";
import report from "./Report";
import support from "./Support";
import cms from "./CMS";
import myprofile from "./MyProfile";
import editprofile from "./EditProfile";
import reviewList from "./ReviewList";

const AccessControl = {
  ...auth,
  ...dashboard,
  ...trips,
  ...booking,
  ...payment,
  ...report,
  ...support,
  ...cms,
  ...myprofile,
  ...editprofile,
  ...reviewList,
};

export default AccessControl;
