

import { SuperReportList } from "../../../pages";
import superadminRoutesMap from "../../../routeControl/superadminRoutes";

export default function route() {
  return [
    {
      path: superadminRoutesMap.SUPERREPORTLIST.path,
      name: "Reports",
      key: superadminRoutesMap.SUPERREPORTLIST.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-analytics",
      element: <SuperReportList />,
    }
  ];
}