import { baseRoutes } from "../../../helpers";

const accessRoute = {
  SUPPORT: {
    path: `${baseRoutes.adminBaseRoutes}/support`,
  }
};

export default accessRoute;

  